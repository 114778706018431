<template>
	<ion-content class="">
		<div class="wrapper">
			<ion-button @click="dismissModal()" class="closeBtn" size="large">
				<ion-icon
					slot="icon-only"
					color="black"
					:icon="closeOutline"
				></ion-icon>
			</ion-button>
			<div class="widget">
				<ion-icon
					slot="icon-only"
					:icon="bulbOutline"
					class="icon animate__animated animate__jackInTheBox"
				></ion-icon>
				<h3 class="">FUNFACT</h3>
				<div class="funfact-txt slabs">{{ fact.funfact }}</div>
				<ion-button color="success" @click="dismissModal()">{{
					fact.button
				}}</ion-button>
			</div>
		</div>
	</ion-content>
</template>

 <script>
import { IonContent, IonButton, IonIcon, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';
import { closeOutline, bulbOutline } from 'ionicons/icons';

export default defineComponent({
	name: 'Modal',
	props: {
		fact: { type: Object },
	},
	data() {
		return {
			content: 'Content',
		};
	},
	methods: {
		dismissModal() {
			modalController.dismiss();
		},
	},
	components: { IonContent, IonButton, IonIcon },
	setup() {
		return { closeOutline, bulbOutline };
	},
});
</script>

<style lang="scss" scoped>
.wrapper {
	background: var(--ion-color-primary-tint);
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;

	.closeBtn {
		position: absolute;
		top: 5px;
		right: 5px;
		--background: transparent;
	}

	.widget {
		background: #ffffff;
		text-align: center;
		padding: 15px;
		min-width: 300px;
		max-width: 80%;
		margin-top: -35px;

		ion-icon {
			font-size: 64px;
			color: var(--ion-color-warning);
			margin-top: 25px;
		}

		.funfact-txt {
			// font-style: italic;
			font-size: 18px;
			margin-bottom: 15px;
			color: var(--ion-color-black-tint);
		}
	}
}
</style>
