<template>
	<ion-page id="wawidetail">
		<ion-header :translucent="true">
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-back-button
						text="Zurück"
						default-href="/wawi"
					></ion-back-button>
				</ion-buttons>
				<ion-title>{{ item.name }}</ion-title>
			</ion-toolbar>
		</ion-header>
		<div class="navbar">
			<div
				class="navitem"
				:class="{
					active: currentIndex === index,
					previous:
						currentIndex - 1 === index ||
						(currentIndex === null &&
							index === item.info.length - 1),
				}"
				v-for="(chapter, index) in item.info"
				:key="chapter.name"
				@click="setIndex(index)"
			>
				<span>{{ chapter.name }}</span>
			</div>
		</div>

		<ion-content
			class="inset-container ion-padding"
			v-if="item.info && !showProducts"
		>
			<h2 class="slabs">{{ item.info[currentIndex].name }}</h2>
			<div
				v-html="item.info[currentIndex].content"
				class="wawi-content"
			></div>
		</ion-content>

		<ion-content class="inset-container" v-if="showProducts">
			<div class="ion-padding">
				<div class="advertorial">GESPONSERT</div>

				<h2 class="slabs">Hersteller & Produkte</h2>
			</div>
			<ion-list>
				<ion-item
					v-for="product in item.products"
					:key="product.id"
					lines="full"
				>
					<ion-avatar slot="start">
						<img :src="imgLink(product.image)" />
					</ion-avatar>
					<ion-label>
						<h3>{{ product.brand }}</h3>

						<h2 class="slabs">{{ product.name }}</h2>
						<p class="infotext">{{ product.infotext }}</p>
						<a
							:href="product.link"
							target="_blank"
							v-if="product.link"
							class="infolink"
							>Mehr dazu auf biohandel.de</a
						>
					</ion-label>
				</ion-item>
			</ion-list>
		</ion-content>

		<div class="bottom-bar">
			<div class="buttons">
				<ion-button
					fill="outline"
					color="black"
					@click="setIndex((currentIndex || item.info.length) - 1)"
					>&lt; Zurück</ion-button
				>
				<ion-button
					color="success"
					@click="setIndex(currentIndex + 1, true)"
					>Weiter ></ion-button
				>
			</div>
		</div>
	</ion-page>
</template>

<script>
import {
	IonPage,
	IonHeader,
	IonBackButton,
	IonButtons,
	IonToolbar,
	IonTitle,
	IonContent,
	IonButton,
	IonList,
	IonItem,
	IonLabel,
	IonAvatar,
	modalController,
} from '@ionic/vue';
import FunfactModal from '@/components/FunfactModal.vue';

export default {
	name: 'WaWiDetail',
	components: {
		IonPage,
		IonHeader,
		IonBackButton,
		IonButtons,
		IonToolbar,
		IonTitle,
		IonContent,
		IonButton,
		IonList,
		IonItem,
		IonLabel,
		IonAvatar,
	},
	data() {
		return {
			item: {},
			currentIndex: 0,
			clickCount: 0,
			showProducts: false,
		};
	},
	methods: {
		async ionViewWillEnter() {
			const { data } = await this.$http.get(
				`/items/warenwissen/${this.$route.params.id}?fields=*.*`
			);
			this.item = data.data;
		},
		async openModal() {
			if (!this.item.funfacts || !this.item.funfacts.length) return;
			let index = 0;
			if (this.currentIndex > 2 && this.item.funfacts.length > 1)
				index = 1;

			let fact = this.item.funfacts[index];
			const modal = await modalController.create({
				component: FunfactModal,
				cssClass: 'funfact',
				componentProps: {
					fact,
				},
			});
			return modal.present();
		},
		setIndex(index, next) {
			if (this.showProducts && next) index = 0;
			this.showProducts = false;
			this.currentIndex = index;

			if (this.currentIndex < 0) this.currentIndex = 0;

			if ((this.item.info[this.currentIndex])) {
				this.showProducts = (["Hersteller & Produkte","Hersteller&Produkte"].includes(this.item.info[this.currentIndex].name));
			} else {
				this.currentIndex = 0;
			}

			this.clickCount++;
			if (this.clickCount % 2 === 0 && this.clickCount < 5)
				this.openModal();
		},
		imgLink(image) {
			return `${
				this.$store.state.imageBase
			}${image}?key=kursicon&access_token=${localStorage.getItem(
				'auth_token'
			)}`;
		},
		openUrl(external_url) {
			window.open(external_url);
		},
	},
};
</script>

<style lang="scss" scoped>
#wawidetail {
	ion-content {
		--background: white;
	}
	.navbar {
		display: flex;
		height: 2.5rem;
		background: white;
		border-bottom: 1px solid var(--ion-color-light);

		.navitem {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0.5rem 0 0.5rem 0.5rem;
			font-size: 12px;
			color: var(--ion-color-dark);
			cursor: pointer;

			span {
				border-right: 0.5px solid var(--ion-color-dark);
				padding-right: 0.5rem;
			}

			&.active {
				color: white;
				background: var(--ion-color-primary);
				span {
					border: none;
				}
			}

			&:last-child,
			&.previous {
				span {
					border: none;
				}
			}
		}
	}

	ion-item {
		cursor: pointer;
	}

	h2 {
		font-weight: bold;
	}

	ion-avatar {
		width: 70px;
		height: 70px;
	}

	ion-label {
		h3 {
			margin-bottom: 0px;
			font-size: 13px;
		}
		h2 {
			margin-bottom: 5px;
			font-size: 16px;
		}
		p {
			font-size: 12px;
		}
	}

	.wawi-content {
		strong {
			font-weight: normal;
			font-size: 18px;
		}
		padding-bottom: 100px;
	}

	.bottom-bar {
		background-color: white;
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		height: 85px;
		z-index: 100;
		border-top: 1px solid var(--ion-color-medium);

		.buttons {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			padding: 1rem;
			ion-button::part(native) {
				padding: 0 2rem;
			}
		}
	}
	.advertorial {
		background-color: var(--ion-color-tertiary);
		display: inline;
		padding-left: 2px;
		padding-right: 2px;
		color: var(--ion-color-dark);
		font-size: 14px;
	}

	@media screen and (min-width: 992px) {
		ion-avatar {
			width: 100px;
			height: 100px;
			margin-inline-end: 25px;
		}
	}

	.infotext {
		white-space: break-spaces;
	}

	.infolink {
		text-decoration: none;
		font-size: 12px;
		font-style: italic;
	}
}
</style>
